<template>
  <div class="login-container">
    <div>
      <h2>图书管理系统</h2>
    </div>
    <div>
      <el-form
        :model="user"
        status-icon
        :rules="rules"
        ref="user"
        label-width="80px"
        label-position="left"
        class="demo-ruleForm"
      >
        <el-form-item label="用户名" prop="name" size="100px">
          <el-input
            type="text"
            v-model="user.name"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input
            type="password"
            v-model="user.password"
            autocomplete="off"
            show-password
          >
          </el-input>
        </el-form-item>
        <el-form-item label="验证码" prop="code" >
          <el-input
            style="width: 50%"
            type="text"
            v-model="user.code"
            placeholder="请输入验证码"
            prefix-icon="el-icon-picture"
          ></el-input>&nbsp;&nbsp;
          <img :src="this.$baseUrl+'/Other/getCaptcha'"
            id="code" 
            height=30px 
            width=150px 
            @click="getCode()" />
    </el-form-item>
        <el-form-item>
          <el-button
            type="text"
            style="display: flex; justify-content: flex-end"
            @click="reset()"
            >忘记密码</el-button
          >
        </el-form-item>
        <el-form-item>
          <div style="margin-top: 20px">
            <el-radio-group v-model="user.user_type" size="medium">
              <el-radio label=0 border>读者</el-radio>
              <el-radio label=1 border>管理员</el-radio>
            </el-radio-group>
          </div>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="login(user)">登录</el-button>
          <el-button @click="register()">注册</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script >
import router from "@/router";
import axios from "axios";
export default {
  created() {
    // this.$axios
    //     .get("/Other/getCaptcha", {
    //       headers: { "Content-type": "application/json" },
    //     })
    //     .then((res) => {
    //       console.log(res);
    //       this.codeImage = res.data;
    //     });
  },
  data() {
    var validateLogin = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("用户名不能为空"));
      } else callback();
    };
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("密码不能为空"));
      } else {
        callback();
      }
    };
    var validateCode = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("验证码不能为空"));
      } else {
        callback();
      }
    };
    return {
      user: {
        name: "",
        password: "",
        user_type: null,
        code: "",
      },
      codeImage:'',
      rules: {
        name: [{ validator: validateLogin, trigger: "blur" }],
        password: [{ validator: validatePass, trigger: "blur" }],
        code: [{ validator: validateCode, trigger: "blur" }],
      },
    };
  },
  methods: {
    login(user) {
      this.$axios
        .post("/User/login", user, {
          headers: { "Content-type": "application/json" },
        })
        .then((res) => {
          if (res.data.status === 200) {
            this.$router.push("/home");
          } else {
            this.$message.error(res.data.declare);
          }
        });
    },
    register() {
      console.log("register");
      this.$router.push("/register");
    },
    reset() {
      this.$router.push("/reset");
    },
    getCode() {
      document.getElementById("code").src=this.$baseUrl+"/Other/getCaptcha?time="+new Date().getTime();
    },
  },
};
</script>
<style>
.login-container {
  position: relative;
  width: 520px;
  max-width: 100%;
  padding: 160px 35px 0;
  margin: 0 auto;
  overflow: hidden;
}
</style>