import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    component: Login,
  },
  {
    path: '/reset',
    name: 'reset',
    component:() => import(/* webpackChunkName: "about" */ '../views/ResetPassword.vue')
  },

  {
    path: '/register',
    name: 'register',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Register.vue')
  },

  {
    path: '/menu',
    component: () => import(/* webpackChunkName: "about" */ '../views/Menu.vue'),
    name: '菜单',
    redirect: '/home',
    meta: {
      title: '图书管理',
      icon: 'book'
    },
    children: [
      {
        path: '/home',
        name: 'home',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/Home.vue')
      },
      {
        path: '/bookInfo',
        name: 'bookInfo',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/BookInfo.vue'),
        meta: {
          title: '图书信息管理',
          icon: 'form',
        }
      },
      {
        path: '/bookState',
        name: 'bookState',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/BookStore.vue'),
        meta: {
          title: '图书信息管理',
          icon: 'form',
        }
      },
      {
        path: '/borrowInfo',
        name: 'bookInfo',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/Borrow_info.vue'),
        meta: {
          title: '借阅信息',
          icon: 'form',
        }
      },
      {
        path: '/user',
        name: 'user',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/UserInfo.vue'),
        meta: {
          title: '用户信息',
          icon: 'form',
        }
      },
    ]
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.

  },

]



const router = new VueRouter({
  routes
})

export default router
