// 1. 引入
import axios from "axios";
// 2. 配置信息
let config = {
// 每次请求的协议、IP地址。 设置该配置后，每次请求路径都可以使用相对路径，例如"/admin/login"
baseURL: process.env.VUE_APP_BASEURL,
// 请求超时时间
timeout: 10000,
// 每次请求携带cookie
withCredentials: true
}
// 3. 创建实例
const instance = axios.create(config)

// 请求拦截器
instance.interceptors.request.use(
    function(config) {
      // 在发送请求之前做些什么
      console.log('Outgoing request:', config);
      // 可以在这里添加token等
      // config.headers.Authorization = `Bearer ${token}`;
      // 然后返回config
      return config;
    },
    function(error) {
      // 对请求错误做些什么
      return Promise.reject(error);
    }
  );
   
  // 响应拦截器
instance.interceptors.response.use(
    function(response) {
      // 对响应数据做点什么
      console.log('Incoming response:', response);
      // 处理响应数据
      return response;
    },
    function(error) {
       // 响应错误处理
        if (error && error.response) {
            // 根据返回的状态码进行重定向或其他处理
            switch (error.response.status) {
            case 445:
                // 用户未登录，可以重定向到登录页面
                window.location.href = '/';
                break;
            // 其他错误处理...
            }
        }
      // 对响应错误做点什么
      return {data:0};
    }
  );

// 4. 导出
export default instance