import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import VueAxios from 'vue-axios';
import axios from './axios'
import moment from 'moment'
import weather from 'vue3-mini-weather'

Vue.use(require('vue-moment'));
Vue.prototype.moment = moment
Vue.use(ElementUI);
Vue.use(weather);
Vue.config.productionTip = false
Vue.use(VueAxios,axios);

Vue.prototype.$axios = axios
Vue.prototype.$baseUrl=process.env.VUE_APP_BASEURL

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
Vue.filter('dateYMDHMSFormat',function(dateStr,pattern='YYYY-MM-DD HH:mm:ss'){
    return moment(dateStr).format(pattern);
  })